.ant-confirm-modal__icon {
  color: #e14b32;
  font-size: 28px;
}
.ant-confirm-modal .ant-modal-header {
  padding: 4px 24px;
  background: #f5f5f5;
  font-size: 13px;
}
.ant-confirm-modal .ant-modal-footer {
  background: #f5f5f5;
}
.ant-confirm-modal .section__content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 15px;
}
.ant-confirm-modal .ant-modal-close-x {
  line-height: 30px;
  width: 30px;
}
