.conversion_actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.conversion_formula_tooltip {
  position: absolute;
  margin: -23px 0 0 70px;
  font-size: 16px;
}
.conversion-window__validate-button {
  position: absolute;
  top: -26px;
  right: 0px;
  padding: 1px 12px;
}
