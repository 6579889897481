.site-header__navigation {
  flex: 1 0;
  overflow: hidden;
}
.site-header .page-help-button {
  display: block;
  height: 30px;
  font-size: 24px;
  color: #aaa;
  padding: 3px 0px;
}
