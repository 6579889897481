.maintenance-calendar {
  display: flex;
  flex-flow: column nowrap;
  padding: 10px;
}
.maintenance-calendar .fc-daygrid-event {
  white-space: break-spaces;
}
.maintenance-calendar__message {
  position: absolute;
  width: 100%;
  z-index: 2;
}
