.toolbar {
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
}
.toolbar_clickable {
  cursor: pointer;
}
.toolbar__entry {
  margin-left: 15px;
}
.toolbar__entry:first-child {
  margin-left: 0;
}
.toolbar__entry_title {
  font-size: 13px;
  font-weight: 700;
  color: #3B3E42;
  margin-left: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.toolbar__entry_spacer {
  flex: 1;
}
.toolbar__entry_set {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.toolbar__set-entry {
  margin-left: 5px;
}
.toolbar__set-entry:first-child {
  margin-left: 0;
}
.toolbar__set-entry_no-padding {
  margin-left: 0;
}
.toolbar_appearance_section-header {
  padding: 0 15px;
  height: 40px;
  background-color: #fbfbfb;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #fff;
}
.toolbar_appearance_section-header.toolbar_style_dark {
  background-color: #eeeeee;
}
.toolbar_appearance_elements-on-line {
  padding: 0 15px;
  height: 20px;
  position: relative;
  z-index: 1;
}
.toolbar_appearance_elements-on-line:before {
  border-top: 1px solid #d8d8d8;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
.toolbar_appearance_elements-on-line .toolbar__entry {
  padding-left: 5px;
  padding-right: 5px;
  background-color: #fff;
}
.toolbar_margin_top,
.toolbar_margin_both {
  margin-top: 20px;
}
.toolbar_margin_bottom,
.toolbar_margin_both {
  margin-bottom: 20px;
}
