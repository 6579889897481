#cw_agent_maintenance_window .cw_section_titlebar,
#cw_service_details_tab_nav-4 .cw_section_titlebar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
#cw_agent_maintenance_window .cw_section_titlebar #global-calendars-selector-wrapper,
#cw_service_details_tab_nav-4 .cw_section_titlebar #global-calendars-selector-wrapper {
  max-width: 500px;
  min-width: 300px;
  padding-right: 15px;
}
#cw_agent_maintenance_window .cw_section_titlebar #global-calendars-selector-wrapper .k-multiselect,
#cw_service_details_tab_nav-4 .cw_section_titlebar #global-calendars-selector-wrapper .k-multiselect {
  height: 30px;
}
