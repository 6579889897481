.rs-table-cell-header .rs-table-cell-content {
  display: flex;
}
.rs-table-row-header .rs-table-cell-header.filtered .rs-table-cell {
  background-color: rgba(0, 162, 214, 0.21);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#cfe6f5), to(#e1eff9));
  background-image: -webkit-linear-gradient(top, #cfe6f5, #e1eff9);
  background-image: -moz-linear-gradient(top, #cfe6f5, #e1eff9);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.rs-table-cell {
  border-width: 1px;
  border-color: #d8d8d8;
}
.rs-table-cell-content {
  font-family: Oxygen, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.rs-table-scrollbar {
  background: white;
}
