.cw_rule_generator_form .k-dropdownlist {
  width: 30%;
}
.cw_rule_generator_form .cw_finish_date-time {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.cw_rule_generator_form .cw_finish_date-time .cw_picker {
  display: flex;
  flex-flow: row nowrap;
  width: 250px;
  gap: 10px;
}
.cw_rule_generator_form .cw_finish_date-time .cw_picker .cw_event_form_hour_picker {
  margin-left: 0px;
}
.cw_rule_generator_form .cw_finish_date-time .cw_picker .k-datepicker {
  width: 140px;
}
.cw_rule_generator_form #cw_monthly_frequency .cw_field,
.cw_rule_generator_form #cw_yearly_frequency {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.cw_rule_generator_form #cw_monthly_frequency .cw_field .k-dropdownlist,
.cw_rule_generator_form #cw_yearly_frequency .k-dropdownlist {
  margin-left: 10px;
}
