.time-period-selector {
  display: flex;
  flex-direction: column;
}
.time-period-selector_custom-selector-mode_popup {
  flex-direction: row;
}
.time-period-selector__selector {
  min-width: 110px;
}
