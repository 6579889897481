.data-source-window .k-content {
  display: flex;
  flex-direction: column;
}
.data-source-window .k-content .data-source-window__content {
  height: calc(100% - 50px);
  overflow: auto;
}
.data-source-window .k-content .toolbar {
  height: 50px;
}
