.ant-modal-root .ant-modal-mask {
  z-index: 10005;
}
.ant-modal-root .ant-modal-wrap {
  z-index: 10005;
}
.ant-modal-root .ant-modal-wrap .ant-modal .ant-modal-body {
  padding: 0;
  display: flex;
  flex: 1;
}
.ant-modal-root .ant-modal-wrap .ant-modal .ant-modal-body > .ant-modal-body__wrapper {
  display: flex;
  flex: 1;
}
.ant-modal-root .ant-modal-wrap .ant-modal .ant-modal-body > .ant-modal-body__wrapper > .section {
  flex: 1;
}
.ant-modal-root .ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
