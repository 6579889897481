.user-tags__icon {
  font-size: 21px;
  color: #aaa;
  padding: 2px;
}
.user-tags__icon_active {
  color: #1890ff;
  outline: 2px solid #1890ff;
}
.user-tags__icon-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 13px 8px;
}
.user-tags__modal .ant-modal-header {
  padding: 8px 20px;
  padding-right: 30px;
}
.user-tags__modal .ant-modal-close-x {
  height: 30px;
  width: 30px;
  line-height: 30px;
}
.user-tags__modal-header {
  font-weight: bold;
  line-height: 14px;
}
.user-tags__modal-header .toolbar__entry_title {
  font-size: 12px;
}
.user-tags__modal-footer {
  padding-top: 10px;
}
.cw_userinfo > .cw_item.user-tags__panel-item {
  padding: 0px;
}
