.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.k-grid td {
  text-overflow: unset;
}
.k-grid td.ellipsis {
  text-overflow: ellipsis;
}
.k-tabstrip {
  font-size: 12px;
}
.k-tabstrip .k-tabstrip-content > .cw_section.cw_section_full {
  position: initial;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  height: 100%;
  width: 100%;
}
.k-tabstrip .k-tabstrip-content {
  border: none;
}
.k-tabstrip.k-tabstrip-top > .k-tabstrip-items-wrapper {
  border-color: #d8d8d8;
}
.k-autocomplete .k-input,
.k-dropdown-wrap .k-input,
.k-numeric-wrap .k-input,
.k-picker-wrap .k-input,
.k-selectbox .k-input,
.k-textbox > input {
  height: 28px;
}
.k-button {
  color: #333333;
  border-color: #cccccc;
  background-color: #ffffff;
  padding: 0.4em 1em;
  border-radius: 3px;
  border-width: 1px;
}
.k-button.k-primary,
.k-button.k-button-solid-primary {
  background-color: #257cba;
  border-color: #1d608f;
  border-width: 0 0 1px;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}
.k-button.k-button-icon,
.k-icon-button {
  width: unset;
}
.k-button-md.k-icon-button > .k-button-icon {
  min-width: 16px;
  min-height: 16px;
}
.k-dropdownlist.k-picker[aria-disabled="false"] {
  background-color: white;
  border-color: #cccccc;
}
.k-input,
.k-picker {
  width: 100%;
}
.k-chip-list {
  align-content: flex-start;
}
.k-widget.k-input,
.k-input {
  border-color: #cccccc;
  color: #333333;
}
.k-grid-norecords {
  background-color: #f4f7f9;
  color: #267fb8;
  padding: 15px 0;
  justify-content: center;
}
.k-menu-link {
  padding: 5px;
}
.k-scheduler-table .k-today {
  background-color: rgba(207, 230, 245, 0.2);
  border-color: rgba(207, 230, 245, 0.8);
}
.k-window .k-window-title {
  font-size: 13px;
  font-weight: 700;
  padding: 0;
}
.k-window .cw_delete_sm_container {
  font: 12px/1.5 'Oxygen', Arial, sans-serif;
}
.k-window .actions {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 12px;
}
.k-window .k-window-titlebar {
  position: relative;
  float: left;
  width: 100%;
  text-align: left;
  border-top: 1px solid #fff;
  border-radius: 3px 3px 0 0;
  background-color: #fbfbfb;
  border-bottom: 1px solid #eee;
  height: 30px;
  padding: 0px;
  box-sizing: border-box !important;
}
.k-window .k-window-titlebar .k-window-title {
  font-size: 13px;
  font-weight: 700;
  color: #3B3E42;
  line-height: 25px;
  text-indent: 7px;
  text-shadow: 0 1px 0 #fff;
  width: 80%;
  overflow: hidden;
  cursor: default;
  text-overflow: ellipsis;
  margin-left: 10px;
}
.k-window .k-window-titlebar .k-window-actions {
  position: absolute;
  top: 0;
  right: 0.5em;
  white-space: nowrap;
}
.k-window .k-window-content {
  font: 12px/1.5 'Oxygen', Arial, sans-serif;
}
.k-window > .k-popup-edit-form .k-edit-buttons {
  margin: 0px;
  display: block;
  border: 1px solid #cccccc;
}
.k-window > .k-popup-edit-form .k-edit-buttons .k-button {
  margin-right: 5px;
}
.k-window .k-resize-handle {
  z-index: 1000000;
}
.k-window .k-resize-handle.k-resize-se {
  opacity: 1;
  right: 1px;
  bottom: 1px;
  width: 12px;
  height: 12px;
  background: transparent;
  box-shadow: inset -1px -1px 0 #aaa, inset -2px -2px 0 #eee, inset -3px -3px 0 #aaa;
}
.k-window-content,
.k-prompt-container {
  padding: 0;
}
.k-window-content:first-child,
.k-prompt-container:first-child {
  padding-top: 0;
}
.k-textbox {
  position: relative;
  border-radius: 4px;
  border-color: #cccccc;
  background-image: none;
  background-position: 50% 50%;
  border-width: 1px;
  border-style: solid;
}
.k-textbox:hover {
  background-color: #ebebeb;
  border-color: #aeaeae;
}
.k-grid {
  font-size: 12px;
}
.k-grid th,
.k-grid td {
  padding: 0.3em 0.6em;
}
.k-grid .k-grid-header th {
  height: 38px;
}
.k-textbox.k-space-right {
  padding-right: 1.9em;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.k-textbox.k-space-right .k-input {
  margin: -1px 0 -1px -1px;
}
.k-window-content:last-child {
  padding-bottom: 0;
}
.k-grid-header .k-grid-filter.k-active,
.k-grid-header .k-header-column-menu.k-active,
.k-grid-header .k-grid-header-menu.k-active,
.k-grid-header .k-hierarchy-cell .k-icon.k-active {
  background-color: #1890ff;
}
.k-checkbox:checked,
.k-checkbox.k-checked {
  background-color: #1890ff;
}
.k-checkbox {
  margin: -2px 6px 0 0;
}
.k-menu:not(.k-context-menu) > .k-item {
  color: unset;
}
.k-animation-container,
.k-animation-container *,
.k-animation-container :after,
.k-block .k-header,
.k-list-container,
.k-widget,
.k-widget *,
.k-widget :before {
  box-sizing: border-box;
}
.k-window-titlebar .k-window-action {
  width: 20px;
  height: 20px;
}
.k-widget.k-tooltip {
  background-color: #fff;
  color: #333;
  border: 1px solid #d8d8d8 !important;
  text-align: left;
}
