.maintenance-calendar {
  --fc-button-bg-color: #257cba;
  --fc-button-border-color: #1d608f;
  --fc-button-active-bg-color: #1d608f;
  --fc-button-active-border-color: #144365;
  --fc-button-hover-bg-color: #1d608f;
  --fc-button-hover-border-color: #144365;
}
.maintenance-calendar .plus-sign {
  color: var(--fc-button-bg-color);
}
.maintenance-calendar .plus-sign:hover {
  color: var(--fc-button-hover-bg-color);
}
.calendar-event {
  padding: 0 1px;
  display: flex;
  flex-direction: row;
}
.calendar-event__title {
  flex: 1;
  margin-left: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.calendar-event__time {
  flex-shrink: 0;
}
.calendar-event__reccurent-event-icon {
  margin-left: 2px;
  flex-shrink: 0;
}
.calendar-event__remove-event-button {
  margin-left: 2px;
  flex-shrink: 0;
}
.calendar-event__remove-event-button:hover {
  color: #CCCCCC;
  transition: all 300ms ease;
}
