.multitoggle {
  display: inline-flex;
  list-style: none;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  color: #54595E;
}
.multitoggle.multitoggle_disabled {
  border-color: #eee;
  cursor: default;
}
.multitoggle__item {
  flex-grow: 1;
  line-height: 1;
  padding: 0.7em;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  border-right: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.multitoggle__item.multitoggle__item_is-selected {
  background: #cfe6f5;
  color: #54595E;
}
.multitoggle__item:first-child {
  border-radius: 2px 0 0 3px;
}
.multitoggle__item:last-child {
  border-radius: 0 2px 2px 0;
  border-right: none;
}
