.ant-tabs-wrapper.ant-tabs {
  font-size: unset;
}
.ant-tabs-wrapper.ant-tabs .ant-tabs > .ant-tabs-nav,
.ant-tabs-wrapper.ant-tabs .ant-tabs > div > .ant-tabs-nav {
  padding: 0 15px;
}
.ant-tabs-wrapper.ant-tabs .ant-tabs-content-holder {
  display: flex;
}
.ant-tabs-wrapper.ant-tabs .ant-tabs-content-holder .ant-tabs-content {
  flex: 1 0 auto;
}
.ant-tabs-wrapper.ant-tabs__tab-wrapper + .ant-tabs-wrapper.ant-tabs__tab-wrapper {
  margin-left: 5px;
}
.ant-tabs-wrapper.ant-tabs__tab-wrapper_dragging {
  opacity: 0.5;
}
.ant-tab-pane-wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
