.rs-table-cell-header-sort-wrapper {
  display: none;
}
.rtable-headercell {
  flex-grow: 1;
}
.rtable-headercell__titleRow {
  display: flex;
  justify-content: left;
  color: #333333;
  cursor: pointer;
  font-family: Oxygen, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
}
.rtable-headercell__filter-box {
  margin-left: auto;
  margin-right: 10px;
}
.rtable-headercell__filter-input {
  margin-top: 4px;
  border-top: 1px solid #dddddd;
  padding-top: 4px;
}
.rtable-headercell__sortIcon {
  display: flex;
}
.rtable-headercell__sortingIndex {
  font-size: 8px;
}
