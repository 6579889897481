.ceeview-grid {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: white;
  min-width: 0;
  min-height: 0;
}
.ceeview-grid_fit {
  flex: 1;
}
.ceeview-grid__wrapper {
  flex: 1;
  display: flex;
  min-height: 0;
}
.ceeview-grid__row {
  display: flex;
  flex-direction: row;
  height: 30px;
  min-height: 30px;
  transition: background 0.2s;
}
.ceeview-grid__row_free-size {
  height: auto;
}
.ceeview-grid__row_free-size .ceeview-grid__cell_nowrap {
  white-space: initial;
}
.ceeview-grid__row_free-size .ceeview-grid__cell_nowrap > a,
.ceeview-grid__row_free-size .ceeview-grid__cell_nowrap > span {
  overflow: visible;
  text-overflow: initial;
  word-wrap: break-word;
  width: 100%;
}
.ceeview-grid__row_free-size .ceeview-grid__cell_align_right.ceeview-grid__cell_nowrap > span {
  text-align: right;
}
.ceeview-grid__row:hover {
  background-color: #fafafa;
}
.ceeview-grid__row_selected {
  background-color: #eaeaea;
}
.ceeview-grid__cell {
  overflow: hidden;
  flex: 0 0 auto;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 4px;
}
.ceeview-grid__cell_disabled,
.ceeview-grid__cell:has(.grid-editable-cell_disabled) {
  background-color: #d8d8d8;
}
.ceeview-grid__cell_nowrap {
  white-space: nowrap;
}
.ceeview-grid__cell_nowrap a,
.ceeview-grid__cell_nowrap span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ceeview-grid__cell_id {
  padding: 0 8px;
}
.ceeview-grid__cell_fixed_left,
.ceeview-grid__cell_fixed_right {
  position: sticky;
  background-color: white;
}
.ceeview-grid__cell_align_right {
  justify-content: right;
}
.ceeview-grid__cell_align_center {
  justify-content: center;
}
.ceeview-grid__cell_align_left {
  justify-content: left;
}
.ceeview-grid__no-data-message {
  text-align: center;
  flex: 1;
  background-color: #f4f7f9;
  color: #267fb8;
  padding: 15px 0;
}
