.create_event {
  overflow: hidden;
  height: 100%;
}
.create_event #cw_calendar_event_form {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.create_event #cw_calendar_event_form .form_content {
  min-width: 460px;
}
.create_event .cw_picker {
  width: 335px;
}
.create_event .cw_picker .k-datepicker {
  width: 160px;
}
.create_event .cw_event_form_hour_picker,
.create_event .cw_event_to_hour_picker {
  position: relative;
  width: 100px;
  margin-left: 80px;
}
