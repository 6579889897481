.form-action-buttons {
  padding: 3px 1em;
}
.form-action-buttons__button {
  margin-left: 10px;
}
.form-action-buttons__button:first-child {
  margin-left: 0;
}
.ant-btn-primary {
  background-color: #257cba;
  background: #257cba;
  border-color: #1d608f;
  border-width: 0 0 1px;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}
