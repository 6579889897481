.coordinates-editor__button {
  position: absolute;
  right: 23px;
  cursor: pointer;
}
.coordinates-editor__coordinate {
  width: 160px;
}
.coordinates-editor__modal .ant-modal-header {
  display: none;
}
.coordinates-editor__modal .form-entry__label {
  width: 45px;
}
.coordinates-editor__modal-content {
  height: calc(100% - 62px);
}
.coordinates-editor__modal-content .section {
  height: 100%;
  width: 100%;
}
.coordinates-editor__modal-footer {
  padding: 15px;
  border-top: 1px solid #cccccc;
}
