.autoLayout__container {
  line-height: 30px;
  margin-left: 20px;
}
.autoLayout__container input {
  margin-top: 8px;
  margin-right: 5px;
}
#cw_servicelog_time_selector {
  position: absolute;
  top: 3px;
  right: 51px;
}
#cw_servicelog_time_selector .multitoggle__item {
  padding: 0.4em;
}
.cw_show_acknowledged_viewer {
  margin-top: -23px;
  margin-bottom: 4px;
  margin-right: 770px;
  margin-left: auto;
}
