.form-entry {
  display: flex;
}
.form-entry_width_default {
  max-width: 400px;
}
.form-entry_width_fit {
  width: 100%;
}
.form-entry_width_half {
  width: 50%;
}
.form-entry_disabled {
  opacity: 0.5;
}
.form-entry__label {
  height: 30px;
  line-height: 30px;
  background-color: #fbfbfb;
  border: 1px solid #d8d8d8;
  padding-right: 5px;
  display: flex;
  position: relative;
  align-items: center;
}
.form-entry__label-additional-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
}
.form-entry_validation-state_invalid.form-entry_horizontal .form-entry__label,
.form-entry_required.form-entry_horizontal .form-entry__label {
  border-right: 2px solid red;
}
.form-entry_validation-state_invalid.form-entry_vertical .form-entry__label,
.form-entry_required.form-entry_vertical .form-entry__label {
  border-bottom: 2px solid red;
}
.form-entry__label-text {
  text-indent: 6px;
  flex: 1 0 auto;
}
.form-entry__label-text_smaller {
  font-size: 11px;
}
.form-entry__label-text_very_small {
  font-size: 9px;
}
.form-entry__field {
  display: flex;
  flex: 1;
  position: relative;
}
.form-entry__field > * {
  flex: 1 1 auto;
  margin-right: 10px;
}
.form-entry__field > *:last-child {
  margin-right: 0;
}
.form-entry_vertical {
  flex-direction: column;
}
.form-entry_vertical .form-entry__label {
  border-radius: 4px 4px 0 0;
  border-bottom: none;
}
.form-entry_vertical .form-entry__field .c-textarea {
  resize: vertical;
  border-radius: 0 0 4px 4px;
}
.form-entry_vertical .form-entry__field .c-textbox {
  border-radius: 0 0 4px 4px;
}
.form-entry_horizontal {
  flex-direction: row;
}
.form-entry_horizontal .form-entry__label {
  width: 120px;
  border-radius: 4px 0 0 4px;
  margin-right: 7px;
  flex-shrink: 0;
}
.form-entry__validating-indicator {
  font-size: 10px;
  position: absolute;
  right: -23px;
  z-index: 1;
  top: 5px;
}
