.ol-map__container {
  flex: 1 0 auto;
  min-height: 300px;
  min-width: 490px;
  height: 100%;
  width: 100%;
}
.ol-map__overlay-container {
  position: relative;
  left: -9px;
  top: -23px;
  font-size: 18px;
  color: crimson;
}
