.cw_table_info_multitoggle_time_selector {
  position: absolute;
  top: 3px;
}
.cw_table_info_dropdown_time_selector {
  position: absolute;
  top: 0;
  width: 70px;
  background-color: #fbfbfb;
}
.no-data-placeholder {
  height: 100%;
  background-color: #f4f7f9;
  padding: 15px 0;
  color: #267fb8;
  text-align: center;
}
