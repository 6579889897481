.state-widget {
  display: flex;
  flex-direction: column;
  min-height: 137px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  background-color: #fbfbfb;
}
.state-widget_highlight {
  border-width: 2px;
}
.state-widget_highlight-critical {
  border-color: #e14b32;
}
.state-widget_highlight-major {
  border-color: #f1ae31;
}
.state-widget_highlight-minor {
  border-color: #f7e400;
}
.state-widget_highlight-none {
  border-color: #7cc623;
}
.state-widget_highlight-invalid {
  border-color: #dddddd;
}
.state-widget__text-block {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
}
.state-widget__main-value {
  font-size: 32px;
  color: #333;
}
.state-widget__main-value_clickable {
  cursor: pointer;
}
.state-widget__sub-value {
  color: #999;
  text-align: center;
}
.state-widget__status-line {
  display: flex;
}
.state-widget__status-line-entry {
  height: 30px;
  color: white;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  text-transform: uppercase;
}
.state-widget__status-line-entry_clickable {
  cursor: pointer;
}
.state-widget__status-line-entry_critical {
  background-color: #e14b32;
}
.state-widget__status-line-entry_major {
  background-color: #f1ae31;
}
.state-widget__status-line-entry_minor {
  background-color: #f7e400;
}
.state-widget__status-line-entry_none {
  background-color: #7cc623;
}
.state-widget__status-line-entry_invalid {
  background-color: #dddddd;
}
