.box-view {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}
.box-view_clickable {
  cursor: pointer;
}
.box-view_border {
  border-width: 1px;
  border-style: solid;
}
.box-view_rounded {
  border-radius: 15px;
}
.box-view_margin_vertical {
  margin: 5px 0;
}
.box-view_margin_horizontal {
  margin: 0 5px;
}
.box-view_margin_both {
  margin: 5px;
}
.box-view_type_warning {
  border-color: #f7ecb5;
  border-bottom-color: #f5e79e;
  background-color: #FCF8E3;
  color: #caad16;
}
.box-view_type_warning-error {
  background-color: #fff1f0;
}
.box-view_type_error {
  background-color: #e8725e;
  border-color: #E1B4AF;
  color: white;
  line-height: 22px;
}
.box-view_type_info {
  background-color: #dff0d8;
  border-color: #d5e9c7;
  color: #44792f;
}
.box-view_type_info > .icon-button {
  color: #333333;
}
.box-view_type_info-2 {
  background-color: #f4f7f9;
  border-color: #d3dfe7;
  color: #267fb8;
}
.box-view_type_info-2 > .icon-button {
  color: #333333;
}
.box-view > .icon-button {
  margin-right: 5px;
  padding: 5px;
}
